import React from 'react'
import { keys } from './qwerty'
import { info } from './info'
import './Keyboard.scss'
import { Key } from './Key'

export const Keyboard: React.FC = () => {
  return (
    <div className="Keyboard Keyboard-Qwerty">
      {keys.map((row, rowIndex) => (
        <div className="Keyboard_Row" data-row={rowIndex} key={rowIndex}>
          {row.map((key, keyIndex) => (
            <Key
              keyConfig={key}
              pos={`${keyIndex}`}
              info={info[Array.isArray(key) ? key[0] : key]}
              key={Array.isArray(key) ? key.join(',') : key}
            ></Key>
          ))}
        </div>
      ))}
    </div>
  )
}
