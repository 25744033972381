import React from 'react'
import { KeyDefine, KeyInfoType } from './info'

const KeyInfoLine: React.FC<{
  line: KeyDefine | string
  className?: string
}> = ({ line, className }) => {
  const define: KeyDefine = typeof line === 'string' ? { text: line } : line
  return (
    <div className={className}>
      {!define.modKey ? null : (
        <div className={`${className}ModKey`}>{define.modKey}</div>
      )}
      <div className={`${className}Main`}>{define.text}</div>
      {!define.category ? null : (
        <div className={`${className}Category`}>{define.category}</div>
      )}
      {!define.tip ? null : (
        <div className={`${className}Tip`}>{define.tip}</div>
      )}
    </div>
  )
}

const KeyInfo: React.FC<{
  info?: KeyInfoType | undefined
}> = ({ info }) => {
  if (!info) {
    return null
  }

  return (
    <div className="KeyInfo">
      {typeof info === 'string' ? (
        <KeyInfoLine line={info} className="KeyInfo_Info"></KeyInfoLine>
      ) : (
        info.map((i) => (
          <KeyInfoLine line={i} className="KeyInfo_Info"></KeyInfoLine>
        ))
      )}
    </div>
  )
}

export const Key: React.FC<{
  keyConfig: string[] | string
  pos: string
  info?: KeyInfoType | undefined
}> = ({ keyConfig, pos, info }) => {
  return (
    <div
      className={
        'Keyboard_Key Keyboard_Key-' +
        (Array.isArray(keyConfig) ? 'Shiftable' : 'Plain')
      }
      data-pos={pos}
    >
      {Array.isArray(keyConfig) ? (
        <>
          <div className="Keyboard_KeyName Keyboard_KeyName-Main">
            {keyConfig[0]}
          </div>
          <div className="Keyboard_KeyName Keyboard_KeyName-Shift">
            {keyConfig[1]}
          </div>
        </>
      ) : (
        <div className="Keyboard_KeyName Keyboard_KeyName-Plain">
          {keyConfig === 'NumLock'
            ? 'Num'
            : keyConfig.replace(/^Num/, '').replace(/\(.*\)/, '')}
        </div>
      )}
      <div className="Keyboard_KeyInfo">
        <KeyInfo info={info}></KeyInfo>
      </div>
    </div>
  )
}
