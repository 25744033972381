export const keys = [
  [
    'Esc',
    'F1',
    'F2',
    'F3',
    'F4',
    'F5',
    'F6',
    'F7',
    'F8',
    'F9',
    'F10',
    'F11',
    'F12',
    'PrtSc',
    'ScrLk',
    'Pause',
  ],
  [
    ['`', '~'],
    ['1', '!'],
    ['2', '@'],
    ['3', '#'],
    ['4', '$'],
    ['5', '%'],
    ['6', '^'],
    ['7', '&'],
    ['8', '*'],
    ['9', '('],
    ['0', ')'],
    ['-', '_'],
    ['=', '+'],
    'BackSpace',
    'Ins',
    'Home',
    'PgUp',
    'NumLock',
    'Num/',
    'Num*',
    'Num-',
  ],
  [
    'Tab',
    'Q',
    'W',
    'E',
    'R',
    'T',
    'Y',
    'U',
    'I',
    'O',
    'P',
    ['[', '{'],
    [']', '}'],
    ['\\', '|'],
    'Del',
    'End',
    'PgDn',
    'Num7',
    'Num8',
    'Num9',
    'Num+',
  ],
  [
    'Caps',
    'A',
    'S',
    'D',
    'F',
    'G',
    'H',
    'J',
    'K',
    'L',
    [';', ':'],
    ["'", '"'],
    'Enter',
    'Num4',
    'Num5',
    'Num6',
  ],
  [
    'Shift(L)',
    'Z',
    'X',
    'C',
    'V',
    'B',
    'N',
    'M',
    [',', '<'],
    ['.', '>'],
    ['/', '?'],
    'Shift(R)',
    'Up',
    'Num1',
    'Num2',
    'Num3',
    'NumEnter',
  ],
  [
    'Ctrl(L)',
    'Win(L)',
    'Alt(L)',
    'Space',
    'Alt(R)',
    'Win(R)',
    'Ctrl(R)',
    'Left',
    'Down',
    'Right',
    'Num0',
    'Num.',
  ],
]
