import React from 'react'
import { Keyboard } from './components/Keyboard/Keyboard'
import { KeyboardTable } from './components/Keyboard/KeyboardTable'

function App() {
  return (
    <div className="App">
      <Keyboard></Keyboard>
      <KeyboardTable></KeyboardTable>
    </div>
  )
}

export default App
