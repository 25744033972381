import React from 'react'
import { info } from './info'

export const KeyboardTable: React.FC = () => {
  return (
    <table className="KeyboardTable">
      <thead>
        <tr>
          <th>按键</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(info).map(([key, action]) => (
          <tr className="KeyboardTable_Row" key={key}>
            <td>{key}</td>
            <td>
              {action.map((a, k) => (
                <div key={k}>
                  {[a.modKey, a.text, a.category, a.tip]
                    .filter((x) => x)
                    .join(' ')}
                </div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
