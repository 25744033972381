export interface KeyDefine {
  modKey?: string
  category?: string
  text: string
  tip?: string
}

export type KeyInfoType = Array<KeyDefine>

export const info: Record<string, KeyInfoType> = {
  W: [
    { text: '前进' },
    { text: '疾跑', modKey: '双击' },
    { text: '低头', category: '飞机' },
  ],
  A: [
    { text: '左移' },
    { text: '添加书签', category: 'JEI' },
    { text: '左舵', category: '飞机' },
  ],
  S: [{ text: '后退' }, { text: '抬头', category: '飞机' }],
  D: [{ text: '右移' }, { text: '右舵', category: '飞机' }],
  R: [
    { category: 'R键整理', text: '整理背包' },
    { text: '物品配方', category: 'JEI', tip: '鼠标指向物品时按' },
    {
      category: '冰火传说',
      text: '喷吐龙息',
      tip: '骑乘龙时发动魔法攻击',
      modKey: '长按',
    },
    { category: '汽车', text: '发动引擎', tip: '需要长按' },
    { category: '飞机', text: '发动引擎' },
  ],
  'Shift(L)': [
    { text: '潜行' },
    {
      text: '解除骑乘',
      tip: '在骑乘状态时按下可解除骑乘，车/马/各种生物/椅子等',
    },
  ],
  'Ctrl(L)': [{ text: '疾跑' }],
  Space: [{ text: '跳跃' }, { category: '汽车', text: '以车为中心摆正位置' }],
  G: [{ category: '冰火传说', text: '发动龙袭', tip: '骑乘龙时发动物理攻击' }],
  F: [{ text: '副手', tip: '和副手交换物品' }],
  Q: [{ text: '丢弃' }],
  E: [{ text: '物品栏' }],
  1: [{ text: '快捷栏1' }],
  2: [{ text: '快捷栏2' }],
  3: [{ text: '快捷栏3' }],
  4: [{ text: '快捷栏4' }],
  5: [{ text: '快捷栏5' }],
  6: [{ text: '快捷栏6' }],
  7: [{ text: '快捷栏7' }],
  8: [{ text: '快捷栏8' }],
  9: [{ text: '快捷栏9' }],
  Enter: [{ text: '打开聊天栏' }],
  Tab: [
    { text: '玩家信息' },
    { modKey: 'Ctrl', text: '玩家 TPS' },
    { text: '搜索', category: 'RS', tip: '打开终端时按 Tab 可跳转到搜索' },
  ],
  P: [
    { text: '飞机界面', category: '飞机' },
    { modKey: 'Ctrl', text: '玩家社交界面' },
  ],
  '/': [{ text: '命令输入' }],
  Esc: [{ text: '暂停菜单' }],
  F3: [{ text: '调试界面' }],
  F1: [{ text: '隐藏 GUI' }],
  F11: [{ text: '全屏' }],
  F5: [{ text: '切换视角' }],
  F2: [{ text: '截图' }],
  F12: [
    {
      text: '切换视距',
      category: '冰火传说',
      tip: '可以调整骑龙时的第三人称视角距离',
    },
  ],
  L: [{ text: '进度', tip: '就是成就树' }],
  M: [
    { text: '地图', category: '体素地图' },
    { modKey: 'Ctrl', text: '导航点界面', category: '体素地图' },
  ],
  Z: [{ text: '缩放小地图', category: '体素地图' }],
  v: [{ text: '切换', category: '星辉魔法' }],
  H: [
    { text: '喇叭', category: '汽车', tip: '在开车时可用' },
    { modKey: 'Ctrl', text: '设置', category: '耐久显示' },
  ],
  I: [
    { text: '汽车GUI', category: '汽车', tip: '你应该在车里' },
    { text: '增大节流阀', category: '飞机', tip: '不应该在车底' },
  ],
  'Alt(L)': [
    { text: '工具菜单', category: '机械动力' },
    {
      text: '临时切换',
      category: '潜影盒查看',
    },
  ],
  Pause: [{ text: '手动减少FPS', category: 'FPS' }],
  End: [{ text: '设置', category: 'FPS' }],
  Home: [{ text: '输入法状态', category: '输入法' }],
  '`': [{ text: '整理容器', category: 'R键整理' }],
  F7: [
    {
      text: '光照显示',
      category: '光照显示',
      tip: '红X代表白天也不满足亮度要求，黄X代表只有晚上不满足亮度要求',
    },
  ],
  F10: [{ text: '帮助', category: 'McJtyLib', tip: '我也不知道这是啥' }],
  '[': [{ text: '下一个目标', category: 'RFTools' }],
  ']': [{ text: '上一个目标', category: 'RFTools' }],
  X: [{ text: '下降', category: '冰火传说', tip: '骑龙时使用' }],
  C: [{ text: '无线合成终端', category: 'RS' }],
  O: [{ text: '换肩', category: '越肩视角' }],
  N: [{ text: '查看内容', category: '潜隐盒查看' }],
  Num1: [{ text: '切换流体', category: 'Waila' }],
  Num0: [{ text: '打开设置', category: 'Waila' }],
  Num2: [{ text: '切换提示框', category: 'Waila' }],
  U: [{ text: '物品用途', category: 'JEI', tip: '鼠标指向物品时按' }],
  K: [{ text: '减小节流阀', category: '飞机' }],
  B: [{ text: '刹车', category: '飞机' }],
}
